import RenderComponents from "./RenderFactory";

const HomeSectionsRender = ({ sections: sections }) => {
  //console.log("Sections--Render",sections);
  if (!sections) {
    return <></>;
  } else if (sections.length > 0) {
    return (
      <>
        {sections.map(function (section, idx) {
          return (
            <RenderComponents
              key={idx}
              data={section.details}
              title={section.title}
            />
          );
        })}
      </>
    );
  }
};

export default HomeSectionsRender;
