import dynamic from "next/dynamic";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Background, Parallax } from "react-parallax";
import useWindowSize from "../../utils/useWindowSize";
import HomeSectionsRender from "../Common/HomeSectionsRender";

import styles from "./HomeBody.module.scss";
const HeroComponent = dynamic(() => import("./HeroComponent"), {
  ssr: false,
});
const HomeSearchComponent = dynamic(
  () => import("../Search/HomeSearchComponent"),
  {
    ssr: false,
  }
);

// Skeleton loader component
const SkeletonLoader = ({ width, height }) => (
  <div
    style={{
      width: `${width}px`,
      height: `${height}px`,
      background:
        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
      backgroundSize: "200% 100%",
      animation: "skeleton-loading 1.5s infinite",
      borderRadius: "8px",
    }}
  ></div>
);

const HomeBody = ({ data: data }) => {
  const size = useWindowSize();
  const url_format =
    "https://cdn.rycofilters.com/etc-squidex-assets/39f5cacb-bc68-4cc4-b4cd-4631fe4f2bcd";

  const {
    mainTitle = "",
    subtitle = "",
    heroBackgroundImage = [],
    heroBackgroundImageMobile = [],
    sections,
  } = data;

  const [desktopBackgroundImage, setDesktopBackgroundImage] = useState("");
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState("");

  useEffect(() => {
    if (heroBackgroundImage.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * heroBackgroundImage.length
      );
      const img = heroBackgroundImage[randomIndex].id;
      const cdn = `${url_format}_${img}_0`;
      setDesktopBackgroundImage(cdn);
    }
    if (heroBackgroundImageMobile.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * heroBackgroundImageMobile.length
      );
      const img = heroBackgroundImageMobile[randomIndex].id;
      const cdn = `${url_format}_${img}_0`;
      setMobileBackgroundImage(cdn);
    }
  }, [heroBackgroundImage, heroBackgroundImageMobile]);

  const isMobile = size.width < 768;

  return (
    <div className='page-wrapper flex-grow-1'>
      <div className={`hero-background w-100 ${styles.BannerSection}`}>
        <Parallax strength={500}>
          <Background>
            {isMobile ? (
              mobileBackgroundImage ? (
                <Image
                  src={mobileBackgroundImage}
                  alt='Ryco Filters Hero Image'
                  width={375}
                  height={667}
                  priority
                  style={{ width: "100vw" }}
                />
              ) : (
                <SkeletonLoader width={375} height={667} />
              )
            ) : desktopBackgroundImage ? (
              <Image
                src={desktopBackgroundImage}
                alt='Ryco Filters Hero Image'
                width={1920}
                height={1080}
                priority
              />
            ) : (
              <SkeletonLoader width={1920} height={1080} />
            )}
          </Background>
          <div
            className={`${styles.GradientContainer} d-flex w-100 flex-column justify-content-end`}
          >
            <HeroComponent {...data}></HeroComponent>
            <HomeSearchComponent {...data}></HomeSearchComponent>
          </div>
        </Parallax>
      </div>

      <HomeSectionsRender {...data}></HomeSectionsRender>
    </div>
  );
};

export default HomeBody;
